/* CSS RESET */
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overscroll-behavior-y: none;
  min-height: 100%;
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
  min-height: 100%;
}


* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.switch-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.video-container {
  margin: 0;
  padding : 0;
  position: absolute;
  top: 0;
  bottom: 40px;
  width: 100%;
  overflow: hidden;

}
@media (min-aspect-ratio: 8/5) {
  .video-container {
    bottom: 0;
  }
}

.video-container video, .video-container img {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.canvas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes logoWindowAnim {
  0% {
    transform: scaleY(0) translateX(290.64%);
  }
  30% {
    transform: scaleY(1) translateX(290.64%);
  }
  60% {
    transform: scaleY(1) translateX(290.64%);
  }
  100% {
    transform: scaleY(1) translateX(0);
  }
}


@keyframes logoTextWrapperAnim {
  0% {
    left: 66.29%;
  }
  60% {
    left: 66.29%;
  }
  100% {
    left: 21.34%;
  }
}

@keyframes logoTextAnim {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes logoSunWrapperAnim {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes logoSunAnim {
  0% {
    transform: translateX(100%);
  }
  15% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeinslidedown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeinslideup {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes arrowshow {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    transform: translateX(3px) translateY(-3px);
    opacity: 1;
  }
  40% {
    transform: translateX(-3px) translateY(3px);
    opacity: 1;
  }
  60% {
    transform: translateX(3px) translateY(-3px);
    opacity: 1;
  }
  80% {
    transform: translateX(-3px) translateY(3px);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
